
import { defineComponent, onMounted, ref, watch } from 'vue';
import FhirComponentsRouter from '@/components/Commons/Fhir/FhirComponentsRouter.vue';
import EcgLoader from '@/components/Commons/EcgLoader.vue';
import EmptyInfo from '@/components/Commons/EmptyInfo/EmptyInfo.vue';
import Repo from '@/apiv2/Repo';
import { dateMinutes, ok } from '@fhir/pixel-commons-js';
import ExpandablePanel from '@/components/Commons/Expandable/ExpandablePanel.vue';
import { getDescriptionFromResource } from '@/components/Patient/PatientUtils';
import { getDateFromResource } from '@fhir/pixel-commons-js/src/index';

export default defineComponent({
  name: 'Document',
  components: { ExpandablePanel, EmptyInfo, EcgLoader, FhirComponentsRouter },
  props: {
    id: {
      type: [String, Array],
      required: true,
      default: ''
    }
  },
  setup(props) {
    const resource = ref(null);
    const loading = ref(false);
    const description = ref('');

    const loadResource = async () => {
      const rid = typeof props.id === 'string' ? props.id : props.id.join('/');
      if (ok(rid)) {
        console.log('loadResource', rid);
        resource.value = null;
        loading.value = true;
        try {
          const { data } = await Repo.fhir.customGet(rid);
          if (data != null) {
            resource.value = data;
            description.value = `${dateMinutes(getDateFromResource(data)) ||
              ''} ${getDescriptionFromResource(data) || ''}`;
          }
        } finally {
          loading.value = false;
        }
      }
    };

    onMounted(() => loadResource());
    watch(
      () => props.id,
      () => loadResource()
    );

    return {
      resource,
      loading,
      description
    };
  }
});
