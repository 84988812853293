<template>
  <transition name="fade">
    <EcgLoader v-if="loading" class="patient-loader">
      {{ $t('base.baseLoading') }}
    </EcgLoader>
    <template v-else>
      <div v-if="resource" class="container">
        <div class="container__header">
          {{ description }}
        </div>
        <div class="container__resource">
          <FhirComponentsRouter :resource="resource" />
        </div>
      </div>
      <EmptyInfo class="margin-empty-info" :delay="1000" v-else />
    </template>
  </transition>
</template>

<script lang="ts">
import { defineComponent, onMounted, ref, watch } from 'vue';
import FhirComponentsRouter from '@/components/Commons/Fhir/FhirComponentsRouter.vue';
import EcgLoader from '@/components/Commons/EcgLoader.vue';
import EmptyInfo from '@/components/Commons/EmptyInfo/EmptyInfo.vue';
import Repo from '@/apiv2/Repo';
import { dateMinutes, ok } from '@fhir/pixel-commons-js';
import ExpandablePanel from '@/components/Commons/Expandable/ExpandablePanel.vue';
import { getDescriptionFromResource } from '@/components/Patient/PatientUtils';
import { getDateFromResource } from '@fhir/pixel-commons-js/src/index';

export default defineComponent({
  name: 'Document',
  components: { ExpandablePanel, EmptyInfo, EcgLoader, FhirComponentsRouter },
  props: {
    id: {
      type: [String, Array],
      required: true,
      default: ''
    }
  },
  setup(props) {
    const resource = ref(null);
    const loading = ref(false);
    const description = ref('');

    const loadResource = async () => {
      const rid = typeof props.id === 'string' ? props.id : props.id.join('/');
      if (ok(rid)) {
        console.log('loadResource', rid);
        resource.value = null;
        loading.value = true;
        try {
          const { data } = await Repo.fhir.customGet(rid);
          if (data != null) {
            resource.value = data;
            description.value = `${dateMinutes(getDateFromResource(data)) ||
              ''} ${getDescriptionFromResource(data) || ''}`;
          }
        } finally {
          loading.value = false;
        }
      }
    };

    onMounted(() => loadResource());
    watch(
      () => props.id,
      () => loadResource()
    );

    return {
      resource,
      loading,
      description
    };
  }
});
</script>

<style lang="scss" scoped>
.margin-empty-info {
  margin-left: auto;
  margin-right: auto;
  margin-top: var(--app-margin);
}

.container {
  background-color: var(--slight-light-gray);

  &__header {
    background-color: var(--accent);
    padding: 10px;
    font-size: 1.2rem;
  }

  &__resource {
    padding: 10px;
  }
}

.progress {
  height: 30px;
}
</style>
